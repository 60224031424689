import React from 'react';

import _ from 'lodash';
import AsyncRender from '@uz/unitz-components-web/AsyncRender';
import PageNotFound from '@uz/unitz-tool-pages/PageNotFound';

const checkValue = async (val) => {
  let checked = val;
  if (_.isUndefined(val)) {
    return false;
  }
  if (_.isFunction(val)) {
    checked = await val();
    return checked;
  }
  if (_.isArray(val)) {
    return await Promise.all(_.map(val, checkValue));
  }
  return checked;
};

export const PageCheckPoint = (allProps) => {
  const {
    children,
    render,
    check,
    isLoading,
    FailureComponent = PageNotFound,
  } = allProps;

  return (
    <AsyncRender render={async () => {
      if (await checkValue(isLoading)) return null;
      if (await checkValue(check) || await checkValue(render)) {
        return <FailureComponent />;
      }
      return children;
    }}/>
  );
};

export default PageCheckPoint;
