import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import useRoute from '@vl/hooks/useGbRouteDe';
import { useFormik } from 'formik';
import _ from 'lodash';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import { waitUntil } from '@vl/mod-utils/waitUntil';

const slugify = require('slugify');

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const route = useRoute();
              const form = useFormik({
                initialValues: {
                  name: '',
                  avatar_url: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    const payload = {
                      created_by: user_id,
                      slug: slugify(_.lowerCase(values.name), '-'),
                      account_profile: {
                        data: {
                          display_name: values.name,
                          avatar_url: _.get(values, 'avatar_url.0.url', ''),
                        },
                      },
                    };

                    const client = await getClient();
                    const account_data = await client.request(
                      gql`
                        mutation CreateAccount($object: b2b_account_insert_input = {}) {
                          account: insert_b2b_account_one(object: $object) {
                            id
                            slug
                            account_profile {
                              id
                              display_name
                              avatar_url
                            }
                          }
                        }
                      `,
                      { object: payload }
                    );
                    const account = _.get(account_data, 'account');
                    const accountUrl = ctx.apply('routeStore.toUrl', 'toolAccountDetail', account);
                    const permissions = await waitUntil(
                      async () => {
                        const account_id = _.get(account, 'id');
                        const resData = await client.request(
                          `query MyQuery(
                            $where: b2b_user_role_permission_bool_exp
                          ) {
                            b2b_user_role_permission(
                              distinct_on: permission_id,
                              where: $where
                            ) {
                              account_id
                              user_id
                              permission_id
                              role_id
                            }
                            b2b_account(
                              where: {id: {_eq: "${account_id}"}}
                            ) {
                              account_profile {
                                id
                                display_name
                                avatar_url
                              }
                            }
                          }`,
                          {
                            where: {
                              account_id: { _eq: account_id },
                              ...(user_id ? { user_id: { _eq: user_id } } : {}),
                            }
                          }
                        );
                        const permissions = _.get(resData, 'b2b_user_role_permission', []);
                        if (!_.get(permissions, 'length')) {
                          await ctx.apply('authModel.reloadApiToken');
                        }
                        return permissions;
                      },
                      (permissions) => _.get(permissions, 'length'),
                      1000 * 5,
                    );
                    console.log('permissions', permissions);
                    route.navigateExternal(accountUrl);
                    // route.navigate(accountUrl);
                  } catch (err) {
                    // show error on form
                    form.setFieldError('name', ctx.apply('i18n.t', 'ZoomToolError.existOrg'));
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                }),
              });

              _.assign(form, {
                canSubmit: form.isValid,

                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });

              return form;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
